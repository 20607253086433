import { message } from 'antd';
import { useState, useEffect } from 'react';

export const facebookSupportLinks = [
  //   'https://www.facebook.com/106388595823875',
  //   'https://www.facebook.com/102731996193244',
  //   'https://www.facebook.com/104144559384068',
  //   'https://www.facebook.com/115915238205323',
  //   'https://www.facebook.com/110056682123349',
  //   'https://www.facebook.com/117506878038914'
];

export default function ConnectCSKH() {
  const [selectedLink, setSelectedLink] = useState(null);

  useEffect(() => {
    const storedLink = localStorage.getItem('selectedLink');
    if (storedLink) {
      setSelectedLink(storedLink);
    } else {
      const randomIndex = Math.floor(
        Math.random() * facebookSupportLinks.length
      );
      const randomLink = facebookSupportLinks[randomIndex];
      setSelectedLink(randomLink);
      localStorage.setItem('selectedLink', randomLink);
    }
    handleContactSupport();
  }, []);

  const handleContactSupport = () => {
    if (selectedLink) {
      if (navigator.platform.includes('Win')) {
        window.open(selectedLink);
      } else {
        window.location.href = selectedLink;
      }
    } else {
      message.info('Đang tải liên kết hỗ trợ. Vui lòng thử lại sau.');
    }
  };

  return (
    <div>
      <button onClick={handleContactSupport}>Liên hệ tư vấn - hỗ trợ</button>
    </div>
  );
}
