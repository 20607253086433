import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Typography, Divider } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'antd/lib/avatar/avatar';
import api from '../../api';
import * as actions from '../../redux/actions/auth';

export default function DetailProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showBankAccount, setShowBankAccount] = useState(false);
  const [showBankInfo, setShowBankInfo] = useState(false); // Thêm state để kiểm soát hiển thị thông tin ngân hàng
  const { profile } = useSelector((state) => state._auth);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    const { data } = await api.get('/users/profile');
    dispatch(actions.initialLogin(data.data));
    if (data.banks) {
      setBanks(data.banks);
      console.log(banks);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
          borderBottom: '1px solid #eee',
        }}
      >
        <motion.div
          whileTap={{ scale: 0.95, x: -10 }}
          onClick={() => history.replace('/me')}
          style={{ padding: 0 }}
        >
          <LeftOutlined style={{ fontSize: 25, color: '#555' }} />
        </motion.div>
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, marginRight: 25 }}
        >
          NGÂN HÀNG TRẢ NỢ
        </Typography.Text>
        <div></div>
      </div>
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Divider>
          <Typography.Text style={{ fontSize: 16 }}>
            Danh sách thông tin ngân hàng trả nợ
          </Typography.Text>
        </Divider>
        {banks &&
          banks.map((bank) => {
            return (
              <div
                style={{
                  width: '100%',
                  border: '1px solid #eeeeee',
                  padding: '10px',
                  marginBottom: 15,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 0px',
                  }}
                >
                  <Typography.Text
                    style={{
                      paddingRight: 10,
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Tên ngân hàng:{' '}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      textAlign: 'right',
                    }}
                  >
                    {bank.bank_name}
                  </Typography.Text>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 0px',
                  }}
                >
                  <Typography.Text
                    style={{
                      paddingRight: 10,
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Số tài khoản:{'  '}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      marginLeft: 'auto',
                      textAlign: 'right',
                    }}
                  >
                    {bank.bank_number}
                  </Typography.Text>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 0px',
                  }}
                >
                  <Typography.Text
                    style={{
                      paddingRight: 10,
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    Chủ tài khoản:{'  '}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      marginLeft: 'auto',
                      textAlign: 'right',
                    }}
                  >
                    {bank.bank_user}
                  </Typography.Text>
                </div>
                {bank.bank_representative && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '5px 0px',
                    }}
                  >
                    <Typography.Text
                      style={{
                        paddingRight: 10,
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      Người đại diện
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        textAlign: 'right',
                      }}
                    >
                      {bank.bank_representative}
                    </Typography.Text>
                  </div>
                )}
                {bank.content_tranfer && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '5px 0px',
                    }}
                  >
                    <Typography.Text
                      style={{
                        paddingRight: 10,
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      Nội dung chuyển khoản
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        textAlign: 'right',
                      }}
                    >
                      {bank.content_tranfer}
                    </Typography.Text>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </motion.div>
  );
}
